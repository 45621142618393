import {
  PasswordlessLoginUI,
  loginUserAddEmail,
  loginUserAddToken,
  useAppDispatch,
  useAppSelector,
  usePasswordlessLogin,
} from "@constituentvoice/cv-elements/web";
import {
  confirmSignIn,
  fetchAuthSession,
  signIn,
  signOut,
} from "aws-amplify/auth";
import { ComponentProps, useCallback } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAppTitle } from "../../hooks/use-client-title";

const TIME_OUT_SECONDS = 60; // 1 minute

export const PasswordlessLogin = () => {
  useAppTitle("Login");

  const {
    currentStep,
    timer,
    codeStatus,
    setCurrentStep,
    setCodeStatus,
    userEmail,
    setTimer,
    setUserEmail,
    getLoginErrorMessage,
    isLoading,
    remainingAttempts,
    setIsLoading,
    setRemainingAttempts,
  } = usePasswordlessLogin();

  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onFormSubmit = useCallback<
    ComponentProps<typeof PasswordlessLoginUI>["onFormSubmit"]
  >(
    async (values, form) => {
      setIsLoading(true);
      if (currentStep === "email") {
        try {
          await signIn({
            username: "cognito.preauth@nwyc.com", // Dummy user to trigger the PreAuth
            password: "password", // Password doesn't matter
            options: {
              authFlowType: "USER_PASSWORD_AUTH",
              clientMetadata: {
                email: values.email, // Who we really are logging in
              },
            },
          }).catch(null);
        } catch (_) {}
        await signOut();

        try {
          await signIn({
            username: values.email,
            options: {
              authFlowType: "CUSTOM_WITHOUT_SRP",
            },
          });
          setCurrentStep("code");
          setCodeStatus("sent");

          await dispatch(loginUserAddEmail(values.email));

          setUserEmail(values.email);
        } catch (err) {
          const errorName = (err as { name?: string })?.name;

          form
            .getFieldValue("email")
            ?.setErrors([getLoginErrorMessage(errorName)]);
        } finally {
          setIsLoading(false);
        }
      } else {
        const challengeResponse = values.code;
        const data = await confirmSignIn({
          challengeResponse,
        });

        if (data.isSignedIn) {
          const session = await fetchAuthSession();
          if (session) {
            const { tokens } = session;
            await dispatch(
              loginUserAddToken(tokens?.accessToken?.toString() || null),
            );
            navigate("/");
          }
        } else {
          setRemainingAttempts((prev) => prev - 1);

          form
            .getFieldValue("code")
            ?.setErrors([
              getLoginErrorMessage(
                remainingAttempts <= 1
                  ? "LimitExceededException"
                  : "CodeMismatchException",
              ),
            ]);
        }
        setIsLoading(false);
      }
    },
    [
      currentStep,
      dispatch,
      getLoginErrorMessage,
      navigate,
      remainingAttempts,
      setCodeStatus,
      setCurrentStep,
      setIsLoading,
      setRemainingAttempts,
      setUserEmail,
    ],
  );

  // User is already logged in.  Navigate to app
  if (token) {
    return <Navigate to={location.state?.redirectTo || "../"} replace={true} />;
  }

  const resendCode = async () => {
    setTimer(TIME_OUT_SECONDS);
    setCodeStatus("sent");

    await signIn({
      username: userEmail,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    });
  };

  return (
    <PasswordlessLoginUI
      isLoading={isLoading}
      remainingAttempts={remainingAttempts}
      codeStatus={codeStatus}
      timer={timer}
      clientLogo={"/images/capitol.png"}
      currentStep={currentStep}
      onFormSubmit={onFormSubmit}
      onResendCodePress={() => {
        void resendCode();
      }}
    />
  );
};
