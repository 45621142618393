import { Link } from "react-router-dom";
import styled from "styled-components";

export const ClientAndUserContainer = styled.header`
  background: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.colors.onAccent};
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1.5rem;
  grid-column: 1/3;
  justify-content: flex-end;
`;

export const ClientContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-right: auto;
`;

export const ClientLogo = styled.img`
  background-color: #fff;
  max-height: 64px;
`;

export const ClientVendor = styled.span`
  font-size: 1.25rem;
`;

export const Nav = styled.nav`
  height: 100%;
`;

export const NavContainer = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.colors.surfaceAlpha[5]};
  list-style: none;
  margin: 0;
  padding: 0%;
  height: 100%;
`;

export const MainNav = styled.ul`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: auto;
`;

export const MainNavItem = styled.li<{ active?: boolean }>`
  border-bottom: 1px solid ${(p) => p.theme.colors.surfaceAlpha[10]};
  background-color: ${(p) =>
    p.active ? p.theme.colors.surfaceAlpha[10] : "unset"};

  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.colors.surfaceAlpha[20]};
  }
`;

export const NavItemIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
`;

export const NavItemText = styled.span`
  flex: 1;
`;

export const NavContainerMobile = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 1rem;
  padding: 1rem;
  background: #454545;
  color: white;
`;

export const ProfileToggleContainer = styled.div`
  position: relative;
`;

export const ProfileToggleDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  z-index: 100;
`;

export const ProfileDropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MoreItemsToggleContainer = styled.div`
  position: relative;
`;

export const MoreItemsToggleDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  z-index: 100;
`;

export const MoreItemsDropdownList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Layout = styled.div<{ shouldShowSidebar?: boolean }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.desktop)} {
    display: grid;
    grid-template-columns: ${(p) =>
        p.shouldShowSidebar ? "minmax(auto, 308px)" : 0} 1fr;
    grid-template-rows: auto 1fr;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  gap: 3rem;
  min-width: 100vw;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletSmall)} {
    padding: 1.5rem;
  }

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletLarge)} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export const DesktopTextLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  gap: 0.5rem;
`;

export const UserMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 0 0;
  gap: 0.75rem;
  ${(p) => p.theme.typography.label.large};

  &:hover {
    background: ${(p) => p.theme.colors.surfaceAlpha[10]};
  }
`;

export const UserMenuItemIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
`;
