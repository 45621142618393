import {
  ChooseYourGroupUI,
  setCurrentUser,
  useAppDispatch,
  useChooseYourGroup,
} from "@constituentvoice/cv-elements/web";
import { useLayoutEffect } from "react";
import { ActivityIndicator } from "react-native-paper";
import { useNavigate } from "react-router-dom";

import { useAppTitle } from "../../hooks/use-client-title";

export const ChooseYourGroupView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useAppTitle("Choose Your Group");

  const { upcomingEvents, isLoading, previousEvents, storeClientOnPress } =
    useChooseYourGroup();

  const allEvents = [...upcomingEvents, ...previousEvents];

  useLayoutEffect(() => {
    dispatch(setCurrentUser());
  }, [dispatch]);

  if (allEvents.length === 1) {
    void storeClientOnPress(allEvents[0]).then(() => {
      navigate(`/${allEvents[0].organization.serviceCode}`);
    });
  }

  if (isLoading) return <ActivityIndicator size={"large"} />;

  return (
    <ChooseYourGroupUI
      upcomingEvents={upcomingEvents}
      previousEvents={previousEvents}
      defaultLogoURL={"/images/capitol.png"}
      onSelectEvent={(event) => {
        void storeClientOnPress(event).then(() => {
          navigate(`/${event?.organization.serviceCode}`);
        });
      }}
      onSelectOrganization={(event) => {
        void storeClientOnPress(event).then(() => {
          navigate(`/${event?.organization.serviceCode}`);
        });
      }}
    />
  );
};
